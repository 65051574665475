.modal {
  outline: none;
  background: white;
  width: 76rem;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1500;
  min-height: 220px;
  border-radius: 12.5px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .modal {
      width: 76rem;
      max-width: 300px;
      min-height: 220px;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}

.modalProduct {
  outline: none;
  background: white;
  width: 76rem;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1500;
  min-height: 220px;
  border-radius: 12.5px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .modalProduct {
      width: 76rem;
      max-width: 300px;
      min-height: 220px;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}


.modalLoader {
  outline: none;
  background: white;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1500;
  border-radius: 12.5px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

.modalPayment {
  outline: none;
  background: white;
  width: 76rem;
  max-width: 400px;
  max-height: 320px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1500;
  min-height: 320px;
  border-radius: 12.5px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .modalPayment {
      width: 76rem;
      max-width: 300px;
      min-height: 320px;
      max-height: 320px;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}

.modalMobileLogin{
  outline: none;
  background: white;
  width: 76rem;
  max-width: 400px;
  max-height: 300px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1500;
  min-height: 270px;
  border-radius: 12.5px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .modalMobileLogin {
      width: 76rem;
      max-width: 300px;
      min-height: 310px;
      max-height: 310px;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}

.modalMobileLogin2{
  outline: none;
  background: white;
  width: 76rem;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1500;
  min-height: 150px;
  border-radius: 12.5px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

@media (max-width: 768px) {
  .modalMobileLogin2 {
      width: 76rem;
      max-width: 300px;
      min-height: 150px;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}

.modalMobile {
  outline: none;
  background: white;
  width: 76rem;
  max-width: 400px;
  max-height: 270px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1500;
  min-height: 270px;
  border-radius: 12.5px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}


@media (max-width: 768px) {
  .modalMobile {
      width: 76rem;
      max-width: 300px;
      min-height: 270px;
      max-height: 270px;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}

.modalMobileCode {
  outline: none;
  background: white;
  width: 76rem;
  max-width: 400px;
  max-height: 270px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 1500;
  min-height: 270px;
  border-radius: 12.5px;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}


@media (max-width: 768px) {
  .modalMobileCode {
      width: 76rem;
      max-width: 300px;
      min-height: 320px;
      max-height: 320px;
    margin-bottom: 0;
    border-radius: 12.5px;
  }
}

  .modalPrefix {
    outline: none;
    background: white;
    font-size: 1.6rem;
    width: 310px;
    max-width: 35%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 15;
    min-height: 200px;
    border-radius: 12.5px;
    will-change: transform;
    transform: translate3d(0, 0, 0);
  }
  
  @media (max-width: 768px) {
    .modalPrefix {
        width: 310px;
        max-width: 70%;
        min-height: 200px;
      margin-bottom: 0;
      border-radius: 12.5px;
    }
  }
